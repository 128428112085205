import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage, ref } from "firebase/storage";
import { collection, getFirestore } from "firebase/firestore";
import { GoogleAuthProvider, signInWithPopup, getAuth } from "firebase/auth";

// const firebaseConfig = {
//   apiKey: "AIzaSyDAM752aLkZuufFaPAVW1m3Aji-WOMU3JU",
//   authDomain: "your-pics-49b51.firebaseapp.com",
//   projectId: "your-pics-49b51",
//   storageBucket: "your-pics-49b51.appspot.com",
//   messagingSenderId: "1013708446192",
//   appId: "1:1013708446192:web:68185b0a561893c3d16535",
//   measurementId: "G-NJ2FVEX33G",
// };

// machineworld012@gmail.com
// const firebaseConfig = {
//   apiKey: "AIzaSyAUSmbQChhGOtZukXsg1YOBbBPzhK5o1sQ",
//   authDomain: "machineworld-517f0.firebaseapp.com",
//   projectId: "machineworld-517f0",
//   storageBucket: "machineworld-517f0.appspot.com",
//   messagingSenderId: "778152060295",
//   appId: "1:778152060295:web:9661a8879a1dd999845f22",
//   measurementId: "G-02JZN429HP",
// };

// machineworld2608@gmail.com
const firebaseConfig = {
  apiKey: "AIzaSyDoEliahwrD1e7s0hL0XqxLFWjJwdRXejM",
  authDomain: "machine-world-2608.firebaseapp.com",
  projectId: "machine-world-2608",
  storageBucket: "machine-world-2608.appspot.com",
  messagingSenderId: "527398901279",
  appId: "1:527398901279:web:a76d1b22349360fb29672a",
  measurementId: "G-PCGKK8KTDD"
};

export const app = initializeApp(firebaseConfig);

getAnalytics(app);

export const firestoreRef = getFirestore(app);

export function getStorageRef(url: string) {
  return ref(getStorage(app), url);
}

export const categoriesCollectionRef = collection(firestoreRef, "categories");
export const projectsCollectionRef = collection(firestoreRef, "projects");
export const contactsCollectionRef = collection(firestoreRef, "contacts");

export const auth = getAuth(app);
const googleAuthProvider = new GoogleAuthProvider();

export const signInWithGoogle = () => signInWithPopup(auth, googleAuthProvider);

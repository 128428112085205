import { doc, getDoc, getDocs, query } from "firebase/firestore";
import { Project } from "types";
import { firestoreRef, projectsCollectionRef } from "./firebase";

export async function getProjects(category = "") {
  const projectsSnapshot = await getDocs(query(projectsCollectionRef));

  const projects: Project[] = projectsSnapshot.docs.map((projectDoc) => ({
    ...({ ...projectDoc.data(), ref: projectDoc.ref } as Project),
    id: projectDoc.id,
  }));

  if (!category) return projects;

  return projects.filter((project) => project.category === category);
}

export async function getProject(projectId: string) {
  const docRef = doc(firestoreRef, "projects", projectId);

  const projectDoc = await getDoc(docRef);

  if (projectDoc.exists())
    return { ...projectDoc.data(), ref: projectDoc.ref } as Project;

  return;
}

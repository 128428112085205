import { FullScreenSpinner } from "components";
import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import CategoryScreen from "screens/Category";
import LandingScreen from "screens/Landing";
import ProjectScreen from "screens/Project";

const AdminAppRoot = React.lazy(() => import("admin-app"));

function App() {
  return (
    <Routes>
      <Route path="/" element={<LandingScreen />} />
      <Route path="/category/:categoryId" element={<CategoryScreen />} />
      <Route path="/project/:projectId" element={<ProjectScreen />} />
      <Route
        path="/admin/*"
        element={
          <Suspense fallback={<FullScreenSpinner />}>
            <AdminAppRoot />
          </Suspense>
        }
      />
    </Routes>
  );
}

export default App;

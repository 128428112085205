import {
  DocumentData,
  DocumentReference,
  QueryDocumentSnapshot,
  QuerySnapshot,
} from "firebase/firestore";
import { useState } from "react";

export function useToggle(initialValue = false) {
  const [isOn, setIsOn] = useState(initialValue);

  function toggle() {
    setIsOn((currentValue) => !currentValue);
  }

  function on() {
    setIsOn(true);
  }

  function off() {
    setIsOn(false);
  }

  return { isOn, toggle, on, off };
}

export function useCounter({
  min = 0,
  max = 10,
  skip = 1,
  initialValue = 0,
}: {
  min?: number;
  max?: number;
  skip?: number;
  initialValue?: number;
}) {
  const [counter, setCounter] = useState(initialValue);

  function increment() {
    setCounter((counter) => (counter + skip > max ? counter : counter + skip));
  }

  function decrement() {
    setCounter((counter) => (counter - skip < min ? counter : counter - skip));
  }

  const isFirst = counter === min;
  const isLast = counter === max;

  return { counter, increment, decrement, isFirst, isLast };
}

export function getDocData(docRef: QueryDocumentSnapshot<DocumentData>) {
  if (!docRef.exists()) return;

  return { ...docRef.data(), id: docRef.id, ref: docRef.ref };
}

export function getDocsData(docRefs: QuerySnapshot<DocumentData>) {
  const data: { id: string }[] = [];

  docRefs.forEach((doc) => {
    const docData = getDocData(doc);
    if (docData) {
      data.push(docData);
    }
  });

  return data;
}

export function docToObjectWithId(doc: QueryDocumentSnapshot<DocumentData>) {
  return { id: doc.id, ...doc.data() };
}

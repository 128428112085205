import { images } from 'assets/pictures'
import { AppPage, Button, ButtonLink } from 'components'
import { BiLoaderAlt } from 'react-icons/bi'
import { useMutation, useQuery } from 'react-query'
import { Category } from 'types'
import { getCategories } from 'utils/category'
import { createContact } from 'utils/contact'

function LandingScreen () {
  return (
    <AppPage>
      <Section id='about-us' invert title='ABOUT US'>
        <img width='100%' src={images.machine} alt='gears' />
        <p className='mt-6'>
          We do custom manufacturing, fabrication,speacial purpose fabrication,
          Lab euipments and Engineering college projects.
        </p>
      </Section>
      <Section id='why-machine-world' title='WHY MACHINE WORLD'>
        <img width='100%' src={images.testImg} alt='gears' />
        <p className='mt-6'>
          We provide high quality manufacturing,custom fabrication and machine
          maintanance for affordable price with high quality customer
          experience.
        </p>
      </Section>
      <Section id='categories' invert title='CATEGORIES'>
        <Categories />
      </Section>
      <Section title='OUR MISSION'>
        <p>
          To help our customers turn their concepts and ideas into real world
          machineries.
        </p>
      </Section>
      <Section id='contact-us' title='CONTACT US' invert>
        <div className='flex flex-wrap gap-4 justify-between'>
          <div>
            <p className='font-bold'>Address:</p>
            <p>Machine World</p>
            <p>#186/2B, Srirampura</p>
            <p>Near Srirampura Ring Road, Mysore 570008</p>
          </div>
          <div>
            <p className='font-bold'>Phone:</p>
            <p>
              <a className='underline' href='tel:+919632307770'>
                +919632307770
              </a>{' '}
              /{' '}
              <a className='underline' href='tel:+919632307771'>
                +919632307771
              </a>
            </p>
          </div>
          <div>
            <p className='font-bold'>Email:</p>
            <a className='underline' href='mailto:info.machineworld@gmail.com'>
              info.machineworld@gmail.com
            </a>
          </div>
        </div>
      </Section>
      <Section id='write-to-us' title='WRITE TO US'>
        <ContactUsForm />
      </Section>
    </AppPage>
  )
}

function ContactUsForm () {
  const { mutateAsync, isLoading } = useMutation(createContact)

  const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault()

    const contactForm = evt.currentTarget

    const formData = new FormData(contactForm)

    const contact = Object.fromEntries(formData)

    mutateAsync({
      name: contact.name as string,
      email: contact.email as string,
      message: contact.message as string
    })
      .then(() => {
        alert(
          'Thanks for contacting us, your message has been recorded, we will get back to you soon!'
        )

        contactForm.reset()
      })
      .catch(() => {
        alert('Something went wrong while recording contact!')
      })
  }

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label htmlFor='name'>
          <div>Name:</div>
          <input
            id='name'
            name='name'
            placeholder='Name'
            className='w-full p-3 text-black'
          />
        </label>
      </div>
      <div className='mt-6'>
        <label htmlFor='email'>
          <div>Email:</div>
          <input
            type='email'
            id='email'
            name='email'
            placeholder='Email'
            className='w-full p-3 text-black'
          />
        </label>
      </div>
      <div className='mt-6'>
        <label htmlFor='message'>
          <div>Message:</div>
          <textarea
            id='message'
            name='message'
            placeholder='Message'
            className='w-full p-3 text-black'
          ></textarea>
        </label>
      </div>
      <Button className='btn-primary mt-6 w-full' isLoading={isLoading}>
        Submit
      </Button>
    </form>
  )
}

function Categories () {
  const {
    isIdle,
    isLoading,
    isError,
    data: categories
  } = useQuery('categories', getCategories)
  if (isLoading || isIdle)
    return (
      <div className='mt-3'>
        <BiLoaderAlt className='animate-spin' />
      </div>
    )

  if (!categories || !categories.length) return <p>No categories found!</p>

  if (isError) return <p>Error fetching categories!</p>

  return (
    <div className='flex flex-wrap place-items-center justify-evenly gap-6'>
      {categories.map(category => (
        <CategoryCard category={category} key={category.name} />
      ))}
    </div>
  )
}

function CategoryCard ({ category }: { category: Category }) {
  return (
    <div className='bg-white p-3 shadow'>
      <h2 className='uppercase font-semibold text-black text-xl'>
        {category.name}
      </h2>
      {category.image ? (
        <img className='mt-3' src={category.image} alt={category.name} />
      ) : null}
      <ButtonLink
        className='w-full inline-block mt-6 text-center text-white'
        to={`/category/${category.name}`}
      >
        View Projects
      </ButtonLink>
    </div>
  )
}

export default LandingScreen

function Section ({
  children,
  className,
  title,
  invert = false,
  ...props
}: React.HTMLAttributes<HTMLElement> & {
  title: string
  invert?: boolean
}) {
  return (
    <section
      className={`flex flex-col px-3 py-6 container m-auto ${
        invert ? 'bg-secondary text-white' : 'bg-gray-100'
      } ${className}`}
      {...props}
    >
      <h1 className={`font-semibold text-3xl`}>{title}</h1>
      <div className='mt-6'>{children}</div>
    </section>
  )
}

import { images } from "assets/pictures";
import { AppPage, ButtonLink, ImageSlider, Loader } from "components";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { Category, Project } from "types";
import { getProjects } from "utils/project";

const testDescription =
  "Seed crusher is used for crushing seeds of many kinds of plants and trees,the machine is very useful for the agricultural industry.it uses solar poweredbattery charging and the crushing force is generated by an electric motor andthe power transmission is by rubber belt.    this machine comes with affordable pricing and it is very portable and light weight and it can be carried to farms on a two wheeler motor cycle by a single person.";

const testProjects: Project[] = [
  {
    id: String(Math.random() + Math.random()),
    name: "PULVERIZER",
    description: testDescription,
    images: [images.testImg],
    synopsis: "",
    category: "T",
  },
  {
    id: String(Math.random() + Math.random()),
    name: "SEED CRUSHER",
    description: testDescription,
    images: [images.testImg],
    synopsis: "",
    category: "T",
  },
  {
    id: String(Math.random() + Math.random()),
    name: "PULVERIZER",
    description: testDescription,
    images: [images.testImg],
    synopsis: "",
    category: "T",
  },
  {
    id: String(Math.random() + Math.random()),
    name: "PEST SPRAYER",
    description: testDescription,
    images: [images.testImg],
    synopsis: "",
    category: "T",
  },
  {
    id: String(Math.random() + Math.random()),
    name: "PULVERIZER",
    description: testDescription,
    images: [images.testImg],
    synopsis: "",
    category: "T",
  },
  {
    id: String(Math.random() + Math.random()),
    name: "PEST SPRAYER",
    description: testDescription,
    images: [images.testImg],
    synopsis: "",
    category: "T",
  },
  {
    id: String(Math.random() + Math.random()),
    name: "SEED CRUSHER",
    description: "",
    images: [images.testImg],
    synopsis: "",
    category: "T",
  },
];

const testCategories: Category[] = [
  {
    id: "55",
    name: "Agriculture",
    image: images.testImg,
  },
];

function CategoryScreen() {
  const { categoryId } = useParams();

  if (!categoryId) return <p>Category not found</p>;

  return (
    <AppPage>
      <div className="p-3 mt-3">
        <h1 className="text-2xl font-semibold">{categoryId} Projects</h1>
        <div className="mt-6">
          <Projects category={categoryId} />
        </div>
      </div>
    </AppPage>
  );
}

function Projects({ category }: { category: string }) {
  const { isLoading, data: projects } = useQuery("projects", () =>
    getProjects(category)
  );

  if (isLoading) return <Loader />;

  if (!projects) return <p>No projects found!</p>;

  return (
    <div className="flex flex-wrap place-items-center justify-evenly gap-6">
      {projects.map((project) => (
        <ProjectCard project={project} key={project.id} />
      ))}
    </div>
  );
}

export default CategoryScreen;

function ProjectCard({ project }: { project: Project }) {
  return (
    <div className="bg-secondary shadow p-3">
      <h2 className="uppercase font-semibold text-white text-xl">
        {project.name}
      </h2>
      <div className="mt-6">
        {project.images ? <ImageSlider images={project.images} /> : null}
      </div>
      <ButtonLink
        className="w-full inline-block mt-6 text-center text-white"
        to={`/project/${project.id}`}
      >
        View Project
      </ButtonLink>
    </div>
  );
}

import React, { useEffect } from "react";
import { IconBaseProps } from "react-icons";
import { BiLoaderAlt } from "react-icons/bi";
import { BsFacebook, BsGearFill, BsInstagram } from "react-icons/bs";
import { FaChevronLeft, FaChevronRight, FaSpinner } from "react-icons/fa";
import { FiMenu } from "react-icons/fi";
import { HiOutlineMail } from "react-icons/hi";
import { IoMdClose } from "react-icons/io";
import { Link, LinkProps, useLocation } from "react-router-dom";
import { Project } from "types";
import { useCounter, useToggle } from "utils";

export function Logo() {
  return (
    <div className="flex place-items-center gap-2 text-xl font-semibold p-3">
      <BsGearFill />
      <span>Machine World</span>
    </div>
  );
}

export function AppHeader({
  className,
  ...props
}: React.HTMLAttributes<HTMLElement>) {
  return (
    <header
      className={`shadow sticky top-0 bg-white z-10 ${
        className ? className : ""
      }`}
      {...props}
    >
      <div className="container mx-auto flex justify-between">
        <Link to="/">
          <Logo />
        </Link>
        {props.children}
      </div>
    </header>
  );
}

export function HeaderNav(props: React.HTMLAttributes<HTMLDivElement>) {
  const menuToggle = useToggle(false);
  const { hash } = useLocation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => menuToggle.off(), [hash]);

  return (
    <div className="flex place-items-center" {...props}>
      <button onClick={menuToggle.on} className="text-2xl p-3">
        <FiMenu />
      </button>
      {menuToggle.isOn ? (
        <div className="fixed flex place-items-center justify-center top-0 left-0 h-screen w-screen bg-white z-30">
          <button
            onClick={menuToggle.off}
            className="fixed top-3 right-3 text-3xl"
          >
            <IoMdClose />
          </button>
          {props.children}
        </div>
      ) : null}
    </div>
  );
}

export function AppNav() {
  return (
    <nav className="flex place-items-center gap-6 flex-wrap flex-col">
      <a className="btn-primary" href="#about-us">
        About Us
      </a>
      <a className="btn-primary" href="#categories">
        Categories
      </a>
      <a className="btn-primary" href="#why-machine-world">
        Why Machine World
      </a>
      <a className="btn-primary" href="#contact-us">
        Contact Us
      </a>
      <a className="btn-primary" href="#write-to-us">
        Write To Us
      </a>
    </nav>
  );
}

export function ButtonLink({ className, ...props }: LinkProps) {
  return <Link className={`btn-primary ${className || ""}`} {...props} />;
}

export function Page({
  children,
  nav,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & { nav: React.ReactChild }) {
  return (
    <div className="screen-body" {...props}>
      <AppHeader>
        <HeaderNav>{nav}</HeaderNav>
      </AppHeader>
      <div>{children}</div>
      <Footer />
    </div>
  );
}

export function AppPage({
  children,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <Page {...props} nav={<AppNav />}>
      {children}
    </Page>
  );
}

export function Footer() {
  return (
    <div className="border-t-2 border-black flex flex-col place-items-center justify-center py-6 bg-white">
      <div className="flex place-items-center justify-center gap-20">
        <Link to="#">
          <BsFacebook className="text-3xl" />
        </Link>
        <Link to="#">
          <HiOutlineMail className="text-4xl" />
        </Link>
        <Link to="#">
          <BsInstagram className="text-3xl" />
        </Link>
      </div>
      <div className="flex gap-16 mt-6 text-black">
        <Link to="#">Privacy policy</Link>
        <Link to="#">Terms of service</Link>
      </div>
    </div>
  );
}

export function FullScreenModal({
  onClose,
  ...props
}: React.HTMLProps<HTMLDivElement> & { onClose: () => void }) {
  const handeClose = () => onClose && onClose();

  return (
    <div className="fixed top-0 left-0 h-screen overflow-y-auto w-screen bg-white z-30">
      <button onClick={handeClose} className="fixed top-3 right-3 text-3xl">
        <IoMdClose />
      </button>
      {props.children}
    </div>
  );
}

interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  icon?: React.ReactChild;
  isLoading?: boolean;
}

export function Button({
  icon,
  className,
  isLoading = false,
  children,
  ...props
}: ButtonProps) {
  return (
    <button
      disabled={isLoading}
      className={`btn-primary ${className ? className : ""}`}
      {...props}
    >
      {children}{" "}
      {isLoading ? (
        <BiLoaderAlt className="animate-spin" />
      ) : icon ? (
        icon
      ) : null}
    </button>
  );
}

export function ProjectCard({ project }: { project: Project }) {
  return (
    <div className="p-3 shadow">
      <h1>{project.name}</h1>
      <ImageSlider images={project.images} />
    </div>
  );
}

export function ImageSlider({ images }: { images: string[] }) {
  const { increment, decrement, counter, isFirst, isLast } = useCounter({
    max: images.length - 1,
  });

  return (
    <div className="flex flex-col place-items-center">
      <div className="flex justify-between place-items-center gap-3">
        <div className="flex-1 flex justify-center">
          <button onClick={decrement} disabled={isFirst}>
            <FaChevronLeft className={`${isFirst ? "text-gray-300" : ""}`} />
          </button>
        </div>
        <div className="flex-2">
          <img src={images[counter]} alt={counter + ""} />
        </div>
        <div className="flex-1 flex justify-center">
          <button onClick={increment} disabled={isLast}>
            <FaChevronRight className={`${isLast ? "text-gray-300" : ""}`} />
          </button>
        </div>
      </div>
      <div className="mt-3"></div>
    </div>
  );
}

export function Loader({ className, ...props }: IconBaseProps) {
  return <FaSpinner className={`animate-spin ${className || ""}`} {...props} />;
}

export function FullScreen({
  children,
  className,
}: { children?: React.ReactChild } & React.HTMLAttributes<HTMLDivElement>) {
  return <div className={`min-h-screen ${className || ""}`}>{children}</div>;
}

export function FullScreenSpinner() {
  return (
    <FullScreen className="flex justify-center place-items-center">
      <Loader className="text-6xl" />
    </FullScreen>
  );
}

import {
  DocumentData,
  DocumentReference,
  getDocs,
  query,
} from "firebase/firestore";
import { Category } from "types";
import { categoriesCollectionRef } from "./firebase";

export async function getCategories() {
  const categoriesSnapshot = await getDocs(query(categoriesCollectionRef));

  const categories: Category[] &
    { categoryDocRef: DocumentReference<DocumentData> }[] = [];

  categoriesSnapshot.forEach((doc) =>
    categories.push({
      ...(doc.data() as Category),
      id: doc.ref.id,
      categoryDocRef: doc.ref,
    })
  );

  return categories;
}

import { images } from "assets/pictures";
import {
  AppPage,
  ButtonLink,
  FullScreenSpinner,
  ImageSlider,
} from "components";
import { useParams } from "react-router-dom";
import { Project } from "types";
import { FaFilePdf } from "react-icons/fa";
import { useQuery } from "react-query";
import { getProject } from "utils/project";

const testDescription =
  "Seed crusher is used for crushing seeds of many kinds of plants and trees,the machine is very useful for the agricultural industry.it uses solar poweredbattery charging and the crushing force is generated by an electric motor andthe power transmission is by rubber belt.    this machine comes with affordable pricing and it is very portable and light weight and it can be carried to farms on a two wheeler motor cycle by a single person.";

const testProjects: Project[] = [
  {
    id: "19",
    name: "PULVERIZER",
    description: testDescription,
    images: [images.testImg],
    synopsis: "",
    category: "T",
  },
  {
    id: String(Math.random() + Math.random()),
    name: "SEED CRUSHER",
    description: testDescription,
    images: [images.testImg],
    synopsis: "",
    category: "T",
  },
  {
    id: String(Math.random() + Math.random()),
    name: "PULVERIZER",
    description: testDescription,
    images: [images.testImg],
    synopsis: "",
    category: "T",
  },
  {
    id: String(Math.random() + Math.random()),
    name: "PEST SPRAYER",
    description: testDescription,
    images: [images.testImg],
    synopsis: "",
    category: "T",
  },
  {
    id: String(Math.random() + Math.random()),
    name: "PULVERIZER",
    description: testDescription,
    images: [images.testImg],
    synopsis: "",
    category: "T",
  },
  {
    id: String(Math.random() + Math.random()),
    name: "PEST SPRAYER",
    description: testDescription,
    images: [images.testImg],
    synopsis: "",
    category: "T",
  },
  {
    id: String(Math.random() + Math.random()),
    name: "SEED CRUSHER",
    description: "",
    images: [images.testImg],
    synopsis: "",
    category: "T",
  },
];

function ProjectScreen() {
  const { projectId } = useParams();

  const { data: project, isLoading } = useQuery("project", () =>
    getProject(projectId as string)
  );

  if (isLoading) return <FullScreenSpinner />;

  if (!project) return <p>No project found!</p>;

  return (
    <AppPage>
      <div className="p-3 pb-10 mt-3">
        <h1 className="font-semibold text-2xl">{project.name}</h1>
        <div className="mt-6">
          <div className="mt-3">
            <ImageSlider images={project.images} />
          </div>
          <h2 className="font-medium text-xl mt-6">Description:</h2>
          <p className="mt-3">{project.synopsis}</p>
        </div>
        {project.synopsisFile ? (
          <a
            target="_blank"
            rel="noreferrer"
            href={project.synopsisFile}
            className="btn-primary bg-secondary text-white w-full mt-6 flex place-items-center justify-center gap-3"
          >
            Download synopsis <FaFilePdf className="text-2xl" />
          </a>
        ) : (
          <p>Synopsis not available!</p>
        )}
      </div>
    </AppPage>
  );
}

export default ProjectScreen;
